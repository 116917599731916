/* src/components/Search.css */
.search-container {
  
  position: absolute;
  top: 32px; /* Adjust based on header */
  left: 0;
  width: 99%;
  display: flex;

  height: calc(100vh - 50px);
  background: #e0c684; /* Opaque white background */
  z-index: 10; /* High z-index to overlay on top of map */
  background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(3px);
  border: solid black;
  border-radius: 10px;
  margin-left: .25%;
  margin-top: .5%;
  flex-direction: column; /* Ensure children are stacked vertically */
}
  
.search-tabs {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.search-tabs button {
  flex: 1;
  padding: 12px;
  margin: 0 0px;
  background-color: #f1f1f1;
  border: 2px solid black;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px; /* Larger font size */
}

.search-tabs button.active {
  background-color: #1d784f;
  color: white;
}

.search-content {
  align-items: center;
  width: 100%;
  max-height: 100%; /* Take up full height of parent container */
}

/* Container for Search Bar and Buttons */
.search-bar-and-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align everything */
  margin-bottom: 0px; /* Add space below the container */
  border-bottom: solid black;
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding:5px;

  background-color: #006b45;
  z-index: 10;
  gap:5px
}

.search-bar {
  display: flex;
  justify-content: center; /* Center-align the search bar */
  gap: 10px; /* Add spacing between input and button */
  margin-bottom: 10px; /* Space between search bar and action buttons */
  width: 40%;
}
.search-bar-button-container {
  padding: 20px;
  gap: 20px;
  display: flex; /* Ensures the input and button align horizontally */
  border-bottom: solid black;
  background-color: #006b45;
  gap: 10px; /* Adjust the spacing between the elements */
  align-items: center; /* Vertically align input and button */
  justify-content: center; /* Center the container */
}

/* Styling for the search input */
.search-content input {
  margin: 0; /* Remove left margin */
  width: 50%; /* Keep the width of the input centered */
  padding: 12px 20px;
  border: 2px solid #1d784f;
  border-radius: 30px;
  border: solid black 2px;
  font-size: 18px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-content input:focus {
  border-color: #007bff;
}

/* Styling for the search button */
.search-content button {
  margin-left: 10px; /* Remove top and left margin */
  margin-right: 10px;
  padding: 12px 20px; /* Adjust padding for better sizing */
  background-color: #9d7715;
  
  color: white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border: solid black 2px;

}

.search-content button:hover {
  background-color: #006b45;
}

.search-results-container {
  
  flex-grow: 1; /* Allow the container to grow within available space */
  max-height: calc(79vh); /* Dynamically calculate height */
  overflow-y: auto; /* Enable vertical scrolling */
  
  margin-left: auto;
  margin-right: auto;
  padding: 10px; /* Inner padding for better layout */
  border-radius: 8px; /* Optional: rounded corners */
  background-color: #006b4600; /* Optional: background color */
  width: 60%;
  
}

/* Optional scrollbar styling for a polished look */
.search-results-container::-webkit-scrollbar {
  width: 8px;
}

.search-results-container::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Custom scrollbar color */
  border-radius: 4px;
}

.search-results-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Top blur effect */
.blur-effect-top,
.blur-effect-bottom {
  position: sticky;
  height: 30px; /* Adjust the height of the blur effect */
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(3px); /* Apply blur effect */
}

/* Top blur sticks to the top */
.blur-effect-top {
  top: 0;
}

/* Bottom blur sticks to the bottom */
.blur-effect-bottom {
  bottom: 0;
}



.search-results-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
}

.search-result-item {
  padding: 15px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px; /* Rounded corners for modern look */
  border: solid black;
  height: 100px;

}
/* Ensure consistent checkbox size and alignment */
.result-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 5%;
}

/* Increase checkbox size */
.result-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid #1d784f; /* Green border */
  border-radius: 4px; /* Rounded checkbox */
  background-color: white; /* Default background */
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* Default styles (for Desktop) */
.result-body {
  display: flex;
  flex-direction: row; /* Keep text and buttons side-by-side on desktop */
  width: 100%;
  align-items: center; /* Align everything properly */
  justify-content: space-between;
}




.search-result-item.even {
  background-color: #f9f9f9; /* Light grey for even items */
}

.search-result-item.odd {
  background-color: #cbcbcb; /* Slightly darker grey for odd items */
}

.search-result-item hr {

  border: 0;
  border-top: 1px solid #ddd;
}

.result-content {
  width: 100%;
  display: flex;
  align-items: center; /* Vertically aligns elements */
  gap: 20px; /* Add space between checkbox and text */
}

.result-buttons-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  row-gap: 0; /* Remove any gap between rows */
  column-gap: 5px; /* Space between buttons horizontally */
  width: 100px;  /* Adjust width so it doesn't overflow */
  height: 120px; /* Adjust height based on your button size */
  align-items: right; /* Ensure buttons stretch to fill available space */
  justify-items: right; /* Center the buttons horizontally */
  margin-top: 2%; /* Move grid upwards */
  margin-right: 100px; /* Move grid to the left */
  justify-self: flex-end; /* If the parent is a grid */
}

.result-buttons {
  display: flex;
  justify-content: flex-end; /* Aligns buttons to the right */
  flex-grow: 1; /* Allows it to take up remaining space */
}

.result-buttons-grid a, .result-buttons-grid button {
  border: solid black;
  font-size: 14px;
  width: 100%; /* Make buttons fill the grid cell */
  height: 75%; /* Ensure the buttons fully fill the grid rows */
  text-align: center;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #7a7a7a; /* Add a subtle border */}

  .result-buttons-grid a:hover, .result-buttons-grid button:hover {
    background-color: #0056b3;
    border-color: #0056b3; /* Change border color on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Make shadow larger on hover */
  }

.result-buttons-grid .tax-button {
  background-color: #28a745;
}

.result-buttons-grid .tax-button:hover {
  background-color: #218838;
}

.result-buttons-grid .clerk-button {
  background-color: #6f42c1;
}

.result-buttons-grid .clerk-button:hover {
  background-color: #593196;
}

.result-buttons-grid .map-button {
  background-color: #17a2b8;
}

.result-buttons-grid .map-button:hover {
  background-color: #138496;
}

.result-buttons-grid .detail-button {
  background-color: #ffc107;
}

.result-buttons-grid .detail-button:hover {
  background-color: #e0a800;
}


.no-results {
  text-align: center;
  font-size: 24px;
  color: black;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* Ensure the search container is properly centered */
  .search-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    height: 92%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(3px);
    border: solid black;
    border-radius: 10px;
    z-index: 10;
    flex-direction: column;
    display: flex;
  }

  /* Center search bar and buttons */
  .search-bar-and-actions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Allow it to take full width of parent */
    box-sizing: border-box; /* Prevents overflow due to padding/border */
    padding: 10px;
    border-bottom: solid black;
    background-color: #006b45;
    gap: 10px; /* Reduce space between elements */
}


  /* Search bar styling */
  .search-bar {
    display: flex;
    flex-direction: row; /* Keep input and button on same line */
    align-items: center;
    width: 95%;
    gap: 2px; /* Reduce gap between input and button */
  }

  /* Search input styling */
  .search-bar input {
    flex: 1; /* Expand to fill space */
    width: 100%;
    max-width: 250px; /* Limit max width */
    font-size: 16px;
    padding: 12px 15px;
    border: 2px solid black;
    border-radius: 30px;
  }

  /* Search button styling */
  .search-bar button {
    width: 80px; /* Make it more square */
    height: 50px;
    font-size: 14px;
    padding: 20px, 10px;
    background-color: #9d7715;
    color: white;
    border-radius: 25px;
    border: solid black 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-bar button:hover {
    background-color: #006b45;
  }

/* Ensure buttons take full width but reduce gap */
.action-buttons {
  display: flex;
  justify-content: space-between; /* Spread buttons evenly */
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap; /* Wrap to the next line if needed */
  box-sizing: border-box;
  gap: 0px; /* Reduce space between buttons */
}

/* Reduce button width & remove extra margins */
.action-button {
  flex: 1; /* Distribute buttons evenly */
  max-width: 27%; /* Prevent buttons from taking too much space */
  height: 60px;
  font-size: 14px;
  text-align: center;
  border-radius: 10px;
  border: solid black 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; /* Ensure no extra margin */
  padding: 10px; /* Adjust padding for balance */
}

  /* Button colors */
  .select-all-button {
    background-color: #007bff;
    color: white;
  }

  .map-selected-button {
    background-color: #28a745;
    color: white;
  }

  .clear-selection-button {
    background-color: #dc3545;
    color: white;
  }

  /* Hover effects */
  .action-button:hover {
    opacity: 0.8;
  }

  /* Style for individual search results */
    
  .search-results-container {
    width: 97%;
    max-height: 65vh; /* Prevent it from taking over */
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column; /* Stack results vertically */
    align-items: center; /* Center children horizontally */
  }
  
  /* Reduce padding and margin in the search results */
  .search-result-item {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    padding: 8px; /* Reduce padding */
    margin-bottom: 5px; /* Reduce margin */
    border: 2px solid black;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: left;
    height: auto;
    width: 90%;
  }
/* Stack text above buttons ONLY on mobile */
 /* Ensure text and buttons stay in a compact column */
 .result-body {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
  height: 150px; /* Let it fit content dynamically */
  padding: 0;
  margin-left: -10%;
}





  /* Reduce spacing inside the details section */
.result-details {
  width: 100%;
  font-size: 14px; /* Slightly smaller text */
  margin-bottom: 5px; /* Reduce space between text and buttons */
  padding: 5px; /* Reduce padding */
}

/* Adjust button container */
.result-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Reduce button size */
.result-buttons-grid {
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  width: 100%;
  gap: 2px; /* Reduce space between buttons */
  padding: 0; /* Remove extra padding */
}
.result-buttons-grid button {
  flex: 1;
  min-width: 23%;
  height: 30px; /* Reduce button height */
  font-size: 12px; /* Reduce font size */
  border-radius: 5px;
  border: solid black 2px;
  padding: 5px; /* Reduce internal padding */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Button colors */
.map-it-button {
  background-color: #17a2b8;
  color: white;
}

.detail-button {
  background-color: #ffc107;
  color: black;
}

.tax-button {
  background-color: #28a745;
  color: white;
}

.clerk-button {
  background-color: #6f42c1;
  color: white;
}

/* Hover effects */
.result-buttons-grid button:hover {
  opacity: 0.8;
}

}

