.intro {
  text-align: center;
  font-family: Arial, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.782); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(5px);
  z-index: 15; /* High z-index to overlay on top of map */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}

.intro-title {
  margin-top: 5%;
  font-size: 2em;
  z-index: 2;
}

.svg-container {
  width: 100%; /* Increase the width for a larger SVG */
  max-width: 50%; /* Ensure responsiveness */
  height: 30%;  /* Maintain aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
}

.static-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below the image */
}

.static-image {

  width: 100%; /* Adjust to fit the container */
  max-width: 600px; /* Set a max width if needed */
  height: auto; /* Maintain aspect ratio */
}
/* Container for the chevron layout */
.chevron-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  position: relative;
}

/* Center the explore button */
.explore-button {
  margin-bottom: 20px;
  margin: auto;
}

/* Chevron-style positioning */
.row {
  display: flex;
  justify-content: space-between; /* Space between buttons on the same row */
  position: relative;
  margin-top: 30px; /* Space between rows */
}

/* Narrow row for middle buttons (Updates and Contact) */
.narrow-row {
  width: 50%; /* Adjusted width for chevron layout */
  display: flex;
  justify-content: space-between;
  margin-left: 300px; /* Increase left and right margin for larger spacing */
  margin-right: 300px;
}

/* Wide row for bottom buttons (Tutorial and Share) */
.wide-row {
  width: 65%; /* Adjusted width for chevron layout */
  display: flex;
  justify-content: space-between;
  margin-left: 400px; /* Increase left and right margin for larger spacing */
  margin-right: 400px;
}
/* Variables converted to static values */
:root {
  --color: #c0392b;
  --color-dark: #a53125;
  --speed: 0.25s;
  --transition: all var(--speed) cubic-bezier(0.310, -0.105, 0.430, 1.400);
}

/* Main Styles */
.button {
  display: block;
  background-color: transparent;
  width: 150px; /* Adjusted button size */
  height: 60px;
  line-height: 60px;
  margin: 0 10px; /* Space between buttons */
  color: black;
  text-align: center;
  border-radius: 5px;
  transition: var(--transition);
  cursor: pointer;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  
  transition: var(--transition);
}

.button span,
.button .icon {
  display: block;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}

.button span {
  width: 72%;
  padding-right: 10px;
  line-height: inherit;
  font-size: 22px;
  text-transform: uppercase;
  left: 0;
  transition: var(--transition);
  position: relative;
}

.button span:after {
  content: '';
  background-color: var(--color-dark);
  width: 2px;
  height: 70%;
  position: absolute;
  top: 15%;
  right: -1px;
}

.button .icon {
  width: 28%;
  right: 0;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
}

.button .icon-caret {
  font-size: 30px;
  vertical-align: middle;
  transition: var(--transition), height var(--speed) ease;
}

.button .icon-caret {
  height: 100%;
}
.button .icon {
  font-style: normal; /* Prevent italicization */
}







.button.success,
.button:hover {
  background-color: rgba(255, 255, 255, 0);
  span {
    left: -72%;
    opacity: 0;
  }

  .icon {
    width: 100%;
  }

  .icon-caret,
  .icon-asterisk {
    font-size: 45px;
  }
}

/* Hover Styles for Individual Buttons */
.explore-button:hover {
  background-color: #2ecc71; /* Red on hover */
  opacity: 0.9;
}

.updates-button:hover {
  background-color: #3498db; /* Blue on hover */
  opacity: 0.9;
}

.contact-button:hover {
  background-color: #f39c12; /* Orange on hover */
  opacity: 0.9;
}

.tutorial-button:hover {
  background-color: #9b59b6; /* Purple on hover */
  opacity: 0.9;
}

.share-button:hover {
  background-color: #e61aca; /* Green on hover */
  opacity: 0.9;
}



/* Animation for SVG paths */
.animated-svg path {
  stroke: #000; /* Set the stroke color of the lines to black (darker) */
  stroke-width: 5; /* Increase line thickness for a more prominent line */
  fill: none; /* Ensure that there's no fill, only a stroke */
  stroke-dasharray: 1000; /* Set a high dash length to initially hide the path */
  stroke-dashoffset: 1000; /* Initially hide the entire line */
  animation: draw 4s ease-in-out forwards; /* Apply the draw animation */
}

/* Define the animation keyframes */
@keyframes draw {
  to {
    stroke-dashoffset: 0; /* Reveal the entire line */
  }
}
@media (max-width: 768px) {
  /* ✅ Keep text visible at the top */
  .intro {
    padding: 10px;
    justify-content: flex-start;
    height: 100dvh;
    overflow-y: auto; /* Allow scrolling if needed */
    box-sizing: border-box; /* ✅ Ensures padding is included in width */
    width: 100%;
  }
  .intro h2 {
    display: none;
  }


  /* ✅ Ensure title stays visible */
  .intro-title {
    margin-top: 20px; /* Reduce top margin */
    font-size: 1.8em; /* Adjust font size for mobile */
  }

  /* ✅ Center buttons properly and reduce gaps */
  .chevron-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* ✅ Fix button spacing within a row */
  .row,
  .narrow-row,
  .wide-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* ✅ Center buttons instead of spacing them out */
    align-items: center;
    gap: 10px; /* ✅ Uniform spacing between buttons */
    margin-top: 15px; /* ✅ Reduce space between rows */
  }

  /* ✅ Standardize button size */
  .button {
    width: 80%;
    max-width: 250px;
    font-size: 18px;
    height: 55px;
    padding: 12px;
    margin: 0; /* ✅ Remove unnecessary margins */
  }

  /* ✅ Ensure images and SVGs scale properly */
  .svg-container {
    max-width: 70%;
    height: auto;
  }

  .static-image-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .static-image {
    max-width: 100%;
    height: auto;
  }
}
