/* src/Map.css */
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.mapboxgl-ctrl-top-left {
  display: none;
}

.basemap-switcher {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background: white;
  padding: 5px;
  border-radius: 90px;
  box-shadow: 0 1px 3px rgba(255, 0, 0, 0.3);
}

#map {
  position: absolute;
  top: 0px; /* Adjusted for MainHeader + ToolPanel height */
  left: 0;
  height: calc(100vh - 50px); /* Adjust height for headers */
  width: 100%;
  z-index: 1;
}
.area-tooltip {
  background-color: white;
  color: black;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid #333;
  font-size: 12px;
}
.info-section {
  padding: 20px;
  height: calc(100vh - 50px); /* Adjusted for MainHeader height */
  overflow-y: auto;
}
.hidden-map {
  display: none;
}

.visible-map {
  display: block;
}
/* General container for the layer control */
.leaflet-control-layers {
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Style the toggle button that opens the layer control */

/* Styling for the base layers container (initially hidden) */
.leaflet-control-layers-list {
  display: none; /* Hidden by default */
}


.layer-selector-container {
  position: absolute;
  bottom: 20px;
  left: 20px; /* Ensure it's well inside the map container */
  z-index: 10; /* Keep it above all other elements */
  pointer-events: auto;
}

.layer-selector-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
}

.layer-icon {
  width: 24px;
  height: 24px;
}

.layer-selector-popup {
  display: none;
  position: absolute;
  bottom: 60px; /* Prevents overlap with the button */
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 10px;
  width: 150px;
  pointer-events: auto;
}

.layer-selector-popup button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
}
.layer-selector-container {
  padding-bottom: 10px; /* Ensures hover covers the gap */
}

.layer-selector-popup button:hover {
  background-color: #f0f0f0;
}

.layer-selector-container:hover .layer-selector-popup {
  display: block;
}

.map-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}


/* The overlay covers the entire .map-container */
.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* A semi-transparent dark background */
  background-color: rgba(0, 0, 0, 0.6); 
  z-index: 12;
  /* center the content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Ensures pointer events are captured by the overlay, 
     blocking clicks on the map behind it. */
  pointer-events: auto; 
  color: #fff; /* text color */
}

.overlay-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.overlay-text {
  margin-bottom: 1rem;
}

.overlay-button {
  background-color: #006b45;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.overlay-button:hover {
  background-color: #005236;
}



@media (max-width: 768px) {
  .map-container {
    position: relative;
    height: 100dvh;
    width: 100%;
    overflow: hidden;
  }
  .layer-selector-container {
    position: absolute;
    bottom: 50px;
    right: 10px; /* Move it to the right */
    left: auto; /* Override left positioning */
    z-index: 10; /* Keep it above all other elements */
  }

  .layer-selector-popup {
    position: absolute;
    bottom: 60px; /* Move it 60px above the button */
    right: 0; /* Align it with the right side of the button */
    left: auto; /* Override any left positioning */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 150px;
    pointer-events: auto;
  }
}
