.login-dropdown {
    position: absolute;
    top: 2px;
    right: 20px;
    z-index: 9999; /* Ensure it's on top of everything */
  }
  
  .login-button {
    background-color: #f1f1f1;
    height: 35px;
    color: black;
    padding: 8px 12px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #006b45;
    color: white;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 40px; /* Spaced below the button */
    right: 0;
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Apply blur effect */
    border-radius: 10px; /* More rounded for modern look */
    z-index: 9999;
    width: 240px; /* Increased width for longer emails */
    display: flex;
    flex-direction: column; /* Stack items */
    align-items: stretch; /* Ensure full width */
    gap: 10px;
    padding: 12px; /* Add spacing inside */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25); /* Stronger modern shadow */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border for contrast */
  }
  
  
  .dropdown-item {
    background-color: transparent;
    border: solid black;
    color: black;
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    width: 97%; /* Full width */
    font-size: 14px; /* Smaller font for better fit */

  }

  .dropdown-item:hover {
    background-color: #9d7715;
    color: white;
  }
  
  .dropdown-item.email {
    font-size: 12px; /* Smaller font for better fit */
    font-weight: bold;
    padding: 8px 12px;
    text-align: left; /* Align email to left */
    justify-content: flex-start; /* Align email to left */
  }
  
/* Last item should not have a border */

/* Hover effect */


/* Button specific styling */
.dropdown-button {
  background-color: transparent;
  border: solid black;
  color: black;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  width: 100%; /* Full width */
}

.dropdown-button:hover {
  background-color: #0056b3;
  color: white;
  border: solid black;
}

/* Special button styling for re-subscribe */
.resubscribe-button {
  background-color: transparent;
  color: black;
  border: solid black;
}

.resubscribe-button:hover {
  background-color: #218838;
  border: solid black;
  color: white;
}

/* Logout button styling */
.logout-button {
  background-color: transparent;
  border: solid black;
  color: black;

}

.logout-button:hover {
  color: white;
  border: solid black;
  background-color: #c82333;
}

/* Ensure Login Dropdown Button is at Bottom-Right */
@media (max-width: 768px) {
  .login-dropdown {
    position: absolute;  /* Make sure it's positioned relative to the viewport */
    top: calc(100dvh - 50px);    
    right: 0; /* Align to the very right */
    width: 25%; /* Same width as planned */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it's above everything */
  }

  .login-button {
    top: calc(100dvh - 50px);
    margin-top: -10px;
    gap: 10px;
    margin-left: 10px;
    height: 35px;
    font-size: 16px;
    background-color: #f1f1f1;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    text-decoration: none;
    color: black;
    width: 200px; /* Ensure it spans the full width of the header */
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    border-radius: 5px;
    border: solid black 2px;
    margin-right: 10px;

  }

  .login-button:hover {
    background-color: #006b45;
    color: white;
  }
  /* Full-screen overlay */
.dropdown-overlay {
  position: fixed; /* Make it cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh; /* Ensure it takes up the full viewport */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  backdrop-filter: blur(10px); /* Apply blur effect */
  z-index: 99999; /* Ensure it's above everything */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Prevent interactions when hidden */
  transition: opacity 0.3s ease-in-out;
}

/* Show overlay when active */
.dropdown-overlay.active {
  opacity: 1;
  pointer-events: auto; /* Allow interactions */
}

/* Close button */
.close-dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border: none;
}

/* Menu items inside overlay */
.dropdown-overlay .dropdown-item{
  background-color: #006b45; /* ✅ White background */
    color: white;
    max-width: 80%;
    padding: 18px; /* ✅ Larger padding for better touchability */
    margin: 15px 0; /* ✅ Increased vertical gap */
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: solid black 2px;
}

.dropdown-overlay .dropdown-button {
    background-color: white; /* ✅ White background */
    color: black;
    max-width: 80%;
    padding: 18px; /* ✅ Larger padding for better touchability */
    margin: 15px 0; /* ✅ Increased vertical gap */
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: solid black 2px;
}


/* Hover effect */
.dropdown-overlay .dropdown-item:hover {
  background-color: #006b45;
  color: white;
}

}




